import React from "react"
import { Form, Icon, Input, Button } from "antd"
import { navigate } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class LeadForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = e => {
    this.setState({ ...this.state, [e.target.name]: e.target.value })

    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values)
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values)
        window.analytics.track("Register lead", this.state)
        typeof window !== "undefined" && window.gtag("event", "Register lead")
        window.fbq("track", "Register lead", {
          content_name: "Landing",
          ...this.state,
        })
        const form = e.target
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": form.getAttribute("name"),
            ...this.state,
          }),
        })
          .then(() => {
            navigate(form.getAttribute("action"))
          })
          .catch(error => alert(error))
      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <form
        name="lead-form"
        className="lead-form"
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        action="/gracias/"
        onSubmit={this.handleSubmit}
      >
        <Input type="hidden" name="form-name" value="lead-form" />
        <Form.Item>
          {getFieldDecorator("username", {
            rules: [
              {
                required: true,
                message: "¡Por favor ingresa tu nombre!",
              },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Tu nombre"
              type="text"
              name="name"
              id="name"
              onChange={this.handleChange}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("phone", {
            rules: [
              {
                required: true,
                message: "¡Por favor ingresa tu número telefónico!",
              },
            ],
          })(
            <Input
              prefix={
                <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              type="tel"
              name="phone"
              id="phone"
              pattern="[0-9]{10}"
              placeholder="Tu número telefónico"
              onChange={this.handleChange}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "¡Por favor ingresa tu correo electrónico!",
              },
            ],
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="email"
              name="email"
              id="email"
              placeholder="Tu correo electrónico"
              onChange={this.handleChange}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            size="large"
            block
          >
            ¡Unirme a Elenas!
          </Button>
        </Form.Item>
      </form>
    )
  }
}

const WrappedForm = Form.create({ name: "lead_form" })(LeadForm)

export default WrappedForm
