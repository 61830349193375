import React from "react"
import { Card, Layout, Button, Typography, Row, Col } from "antd"
// import Slider from "react-slick"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import SEO from "../components/seo"
import Logo from "../components/logo"
import Banner from "../components/banner"
import Form from "../components/form"
import Colors from "../utils/colors"

import women from "../images/social-proof.jpg"
const { Header, Content } = Layout

const { Title } = Typography

const LandingPage = ({ data }) => {
  console.log(data)
  const {
    bannerTitle,
    bannerImage,
    bannerLink,
    bannerCta,
    valuePropTitle,
    valuePropIntro,
    videoTitle,
    videoIntro,
    videoUrl,
    socialProofTitle,
    socialProofIntro,
    shareIntro,
    shareTitle,
    testimonialAuthor,
    testimonialAvatar,
    testimonialContent,
  } = data.contentfulLandingPage

  const myRef = React.createRef()

  console.log(bannerTitle)

  const handleScrollToElement = event => {
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <div className="App" style={styles.welcomeContainer}>
      <Layout style={styles.layout}>
        <SEO title="Elenas Landing" />
        <Header style={styles.header}>
          <Logo />
        </Header>
        <Content style={styles.container}>
          <Banner
            title={bannerTitle}
            image={bannerImage}
            link={bannerLink}
            cta={bannerCta}
            handleScroll={handleScrollToElement}
          />
          <section
            ref={myRef}
            className="value-props"
            style={{
              ...styles.section,
              ...styles.textCenter,
              paddingBottom: 38,
            }}
          >
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ flexDirection: "column", flexFlow: "row wrap" }}
            >
              <Col sm={24} md={12}>
                <Title style={{ ...styles.sectionTitle }}>
                  {valuePropTitle
                    ? valuePropTitle
                    : "¡Vender con Elenas es muy fácil!"}
                </Title>
                <p
                  style={{
                    ...styles.sectionText,
                    fontSize: 18,
                    marginBottom: 48,
                  }}
                >
                  {valuePropIntro
                    ? valuePropIntro
                    : "Solo tienes que enfocarte en compartir los productos y asesorar a tus clientes desde el proceso de compra hasta la recepción de su orden para recibir tu comisión por cada producto."}
                </p>
              </Col>
            </Row>
            <Row gutter={50}>
              <Col sm={24} md={8}>
                <img
                  alt=""
                  style={styles.valuePropImg}
                  src={require("../images/vp-1.svg")}
                />
                <Title style={styles.valuePropTitle} level={3}>
                  Maneja tu tiempo
                </Title>
                <p
                  style={{
                    ...styles.sectionText,
                    fontSize: 18,
                  }}
                >
                  ¡Comparte los productos desde cualquier lugar y dedica más
                  tiempo a tu familia!
                </p>
              </Col>
              <Col sm={24} md={8}>
                <img
                  alt=""
                  style={styles.valuePropImg}
                  src={require("../images/vp-2.svg")}
                />
                <Title style={styles.valuePropTitle} level={3}>
                  Logra tus metas
                </Title>
                <p
                  style={{
                    ...styles.sectionText,
                    fontSize: 18,
                  }}
                >
                  ¡Tú decides qué tan lejos quieres llegar! Construye tu futuro.
                </p>
              </Col>
              <Col sm={24} md={8}>
                <img
                  alt=""
                  style={styles.valuePropImg}
                  src={require("../images/vp-3.svg")}
                />
                <Title style={styles.valuePropTitle} level={3}>
                  Vende las mejores marcas
                </Title>
                <p
                  style={{
                    ...styles.sectionText,
                    fontSize: 18,
                  }}
                >
                  Más de 50 marcas y miles de productos que puedes compartir.
                </p>
              </Col>
            </Row>
          </section>
          <section
            className="video"
            style={{
              ...styles.section,
              ...styles.textCenter,
              ...styles.bgWhite,
              paddingBottom: 38,
            }}
          >
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ flexDirection: "column", flexFlow: "row wrap" }}
            >
              <Col sm={24} md={13}>
                <Title style={{ ...styles.sectionTitle }}>
                  {videoTitle ? videoTitle : "¿Cómo comenzar con Elenas?"}
                </Title>
                <p
                  style={{
                    ...styles.sectionText,
                    fontSize: 18,
                  }}
                >
                  {videoIntro
                    ? videoIntro
                    : "Comenzar a ganar dinero por tus ventas en Elenas es muy fácil. Sigue los pasos en el video a continuación y muy pronto harás tu primera venta."}
                </p>
                <div className="video-wrapper">
                  <iframe
                    title="¿Cómo funciona Elenas?"
                    style={{ maxWidth: "100%" }}
                    width="560"
                    height="315"
                    src={
                      videoUrl
                        ? videoUrl
                        : "https://www.youtube.com/embed/Z5g7JE9Jexg"
                    }
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </Col>
            </Row>
          </section>
          <section
            className="press"
            style={{
              ...styles.section,
              ...styles.textCenter,
              paddingBottom: 38,
            }}
          >
            <Row gutter={80}>
              <Col sm={24} md={12}>
                <img
                  alt=""
                  style={styles.pressImg}
                  src={require("../images/caracol.svg")}
                />
                <p
                  style={{
                    ...styles.pressText,
                  }}
                >
                  “Elenas reduce tiempos de espera tanto para vendedores como
                  para clientes.”
                </p>
              </Col>
              <Col sm={24} md={12}>
                <img
                  alt=""
                  style={styles.pressImg}
                  src={require("../images/techcrunch.svg")}
                />

                <p
                  style={{
                    ...styles.pressText,
                  }}
                >
                  “Una de 15 startups de Latinoamérica que estará presente en el
                  escenario de TechCrunch.”
                </p>
              </Col>
            </Row>
          </section>
          <section
            style={{
              ...styles.section,
              ...styles.bgGradient,
              ...styles.textCenter,
              backgroundImage: `url(${women})`,
              display: "flex",
            }}
            className="social-proof"
          >
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ flexDirection: "column", flexFlow: "row wrap", flex: 1 }}
            >
              <Col sm={24} md={18}>
                <div style={styles.sectionTextWrap}>
                  <Title
                    style={{
                      ...styles.sectionTitle,
                      ...styles.textWhite,
                      fontSize: 40,
                    }}
                  >
                    {socialProofTitle
                      ? socialProofTitle
                      : "¡Ya somos más de 14.000 mujeres poderosas!"}
                  </Title>
                  <p
                    style={{
                      ...styles.sectionText,
                      ...styles.textWhite,
                      fontSize: 18,
                    }}
                  >
                    {socialProofIntro
                      ? socialProofIntro
                      : "Únete a nuestra red de embajadoras y comienza a ganar dinero adicional."}
                  </p>
                </div>
              </Col>
              <Col sm={24} md={12}>
                <Form />
              </Col>
            </Row>
          </section>
          <section
            className="requirements"
            style={{
              ...styles.section,
            }}
          >
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{
                ...styles.textCenter,
                flexDirection: "column",
                flexFlow: "row wrap",
              }}
            >
              <Col sm={24} md={13}>
                <Title style={{ ...styles.sectionTitle }}>
                  Que necesitas para registrarte
                </Title>
                <p
                  style={{
                    ...styles.sectionText,
                    fontSize: 18,
                    marginBottom: 48,
                  }}
                >
                  Conoce los requisitos, compromisos de la embajadora y proceso
                  de registro.
                </p>
              </Col>
            </Row>
            <Row gutter={50}>
              <Col sm={24} md={8}>
                <img
                  alt=""
                  style={styles.requirementImg}
                  src={require("../images/requirements-1.svg")}
                />
                <Title style={styles.valuePropTitle} level={3}>
                  Requisitos
                </Title>
                <ul
                  className="requirements-list"
                  style={{
                    ...styles.sectionText,
                    fontSize: 14,
                  }}
                >
                  <li>Ser mayor de edad (+18 años)</li>
                  <li>Identificación (cédula de ciudadania o extranjería)</li>
                  <li>Tener una cuenta Nequi activa</li>
                </ul>
              </Col>
              <Col sm={24} md={8}>
                <img
                  alt=""
                  style={styles.requirementImg}
                  src={require("../images/requirements-2.svg")}
                />
                <Title style={styles.valuePropTitle} level={3}>
                  Compromisos
                </Title>
                <ul
                  className="requirements-list"
                  style={{
                    ...styles.sectionText,
                    fontSize: 14,
                  }}
                >
                  <li>Compartir los productos con tus clientes</li>
                  <li>Estar al tanto de las ordenes</li>
                  <li>Asesorar al cliente en el proceso de compra</li>
                </ul>
              </Col>
              <Col sm={24} md={8}>
                <img
                  alt=""
                  style={styles.requirementImg}
                  src={require("../images/requirements-3.svg")}
                />
                <Title style={styles.valuePropTitle} level={3}>
                  Proceso
                </Title>
                <ul
                  className="requirements-list"
                  style={{
                    ...styles.sectionText,
                    fontSize: 14,
                  }}
                >
                  <li>Descarga el app desde el Play Store</li>
                  <li>Crea tu cuenta y agrega tu información personal </li>
                  <li>Comparte productos y genera tu primera venta</li>
                </ul>
              </Col>
            </Row>
          </section>
          <section
            className="products"
            style={{
              ...styles.section,
              ...styles.textCenter,
              ...styles.bgWhite,
            }}
          >
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{
                flexDirection: "column",
                flexFlow: "row wrap",
              }}
            >
              <Col sm={24} md={14}>
                <Title style={{ ...styles.sectionTitle }}>
                  {shareTitle
                    ? shareTitle
                    : "¿Lista para hacer tu primera venta?"}
                </Title>
                <p
                  style={{
                    ...styles.sectionText,
                    fontSize: 18,
                    marginBottom: 48,
                  }}
                >
                  {shareIntro
                    ? shareIntro
                    : "Comparte tus primeros productos con tus amigos y familiares para generar tu primera venta y ganar tu primera comisión."}
                </p>
                <Card
                  hoverable
                  bordered={false}
                  style={{
                    width: 288,
                    margin: "0 auto",
                    textAlign: "left",
                    boxShadow: "0 1px 8px rgba(164, 146, 209, .2)",
                  }}
                  cover={
                    <img
                      alt="product-1"
                      src={require("../images/producto-1@2x.jpg")}
                    />
                  }
                >
                  <div>
                    <p style={styles.productBrand}>Dermanat</p>
                    <Title level={4} style={styles.productName}>
                      Exfoliante de Coco
                    </Title>
                    <p style={styles.productPrice}>$18.000</p>
                    <p style={styles.productDecription}>
                      Este producto es especial para la limpieza de la piel,
                      especialmente de esas areas oscuras. ¡Recomiéndalo a tus
                      amigas más cercanas!
                    </p>
                    <Button
                      style={styles.productShareButton}
                      block
                      size="large"
                      onClick={() => {
                        window.analytics.track("Download from share link")
                        typeof window !== "undefined" &&
                          window.gtag("event", "Download from share link")
                        window.fbq("track", "Download from share link", {
                          content_name: "Landing",
                        })
                      }}
                    >
                      <OutboundLink
                        href="http://bit.ly/elenas-landing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          style={{
                            display: "inline-block",
                            maxHeight: 18,
                            margin: 8,
                          }}
                          alt=""
                          src={require("../images/share-square.svg")}
                        ></img>
                        Compartir
                      </OutboundLink>
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          </section>
          <section
            className="brands"
            style={{ ...styles.section, textAlign: "center" }}
          >
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{
                flexDirection: "column",
                flexFlow: "row wrap",
              }}
            >
              <Col sm={24} md={14}>
                <Title
                  style={{
                    ...styles.sectionTitle,
                    marginBottom: 50,
                    fontSize: 24,
                  }}
                >
                  ¡Conoce nuestras Marcas Poderosas!
                </Title>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle" gutter={50}>
              <Col>
                <img
                  alt="Logo Aloe del Rio"
                  style={styles.brandLogo}
                  src={require("../images/logo-aloedelrio@2x.png")}
                />
                <Title style={styles.brandName} level={4}>
                  Aloe del Rio
                </Title>
              </Col>
              <Col>
                <img
                  alt="Logo Bio Essens"
                  style={styles.brandLogo}
                  src={require("../images/logo-bioessens@2x.png")}
                />
                <Title style={styles.brandName} level={4}>
                  Bio Essens
                </Title>
              </Col>
              <Col>
                <img
                  alt="Logo Leche Pal Pelo"
                  style={styles.brandLogo}
                  src={require("../images/logo-lechepalpelo@2x.png")}
                />
                <Title style={styles.brandName} level={4}>
                  Leche Pal Pelo
                </Title>
              </Col>
              <Col>
                <img
                  alt="Logo Dermanat"
                  style={styles.brandLogo}
                  src={require("../images/logo-dermanat@2x.png")}
                />
                <Title style={styles.brandName} level={4}>
                  Dermanat
                </Title>
              </Col>
              <Col>
                <img
                  alt="Logo W7"
                  style={styles.brandLogo}
                  src={require("../images/logo-w7@2x.png")}
                />
                <Title style={styles.brandName} level={4}>
                  W7
                </Title>
              </Col>
            </Row>
          </section>
          <section
            style={{
              ...styles.section,
              ...styles.bgGradient,
              ...styles.textCenter,
            }}
          >
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ flexDirection: "column", flexFlow: "row wrap" }}
            >
              <Col sm={24} md={16}>
                <img
                  style={{ borderRadius: "50%", maxHeight: 80 }}
                  alt="Autor del Testimonio"
                  src={
                    testimonialAvatar && testimonialAvatar.sizes
                      ? testimonialAvatar.sizes.src
                      : require("../images/testimonial@2x.jpg")
                  }
                ></img>
                <Title
                  level={4}
                  style={{
                    ...styles.sectionTitle,
                    ...styles.textWhite,
                    fontSize: "24px",
                    lineHeight: "32px",
                  }}
                >
                  {testimonialContent
                    ? testimonialContent
                    : "“Una buena manera de tener ingresos extras. Es muy rápida la entrega de productos, por lo que se pueden vender con la certeza de que llegan a tiempo.“"}
                </Title>
                <p
                  style={{
                    ...styles.sectionText,
                    ...styles.textWhite,
                    fontSize: 16,
                  }}
                >
                  <em>
                    - {testimonialAuthor ? testimonialAuthor : "Marcela Lopez"}
                  </em>
                </p>
                <Button
                  size="large"
                  onClick={() => {
                    window.analytics.track("Download from footer link")
                    typeof window !== "undefined" &&
                      window.gtag("event", "Download from footer link")
                    window.fbq("track", "Download from footer link", {
                      content_name: "Landing",
                    })
                  }}
                >
                  <OutboundLink
                    href="http://bit.ly/elenas-landing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ¡Instala el app!
                  </OutboundLink>
                </Button>
              </Col>
            </Row>
          </section>
          <footer className="footer">
            <Row
              type="flex"
              justify="center"
              style={{
                flexDirection: "row",
                flexFlow: "row wrap",
                justifyContent: "space-around",
              }}
            >
              <Col col={24} sm={8} md={12}>
                <img
                  alt="Logo Elenas"
                  style={{ maxHeight: 24 }}
                  src={require("../images/logo-p.svg")}
                ></img>
              </Col>
              <Col col={24} sm={16} md={12}>
                <p style={{ textAlign: "right", fontSize: 12, marginTop: 8 }}>
                  © 2019 Elenas | Todos los derechos reservados
                </p>
              </Col>
            </Row>
          </footer>
        </Content>
      </Layout>
    </div>
  )
}

export default LandingPage

export const landingQuery = graphql`
  query($slug: String!) {
    contentfulLandingPage(slug: { eq: $slug }) {
      title
      slug
      bannerTitle
      bannerCta
      bannerLink
      bannerImage {
        sizes(sizes: "280px") {
          src
        }
      }
      valuePropTitle
      valuePropIntro
      videoTitle
      videoIntro
      socialProofTitle
      socialProofIntro
      shareIntro
      shareTitle
      testimonialAuthor
      testimonialAvatar {
        sizes(sizes: "96px") {
          src
        }
      }
      testimonialContent
    }
  }
`

const styles = {
  welcomeContainer: {},
  container: {
    minHeight: "auto",
  },
  header: {
    position: "absolute",
    backgroundColor: "transparent",
    left: 0,
    right: 0,
    paddingTop: 16,
  },
  layout: {
    flex: 1,
    backgroundColor: "#FAFAFF",
  },
  banner: {},
  bannerTextWrap: {},
  bannerTitle: {
    color: "#fff",
  },
  bannerText: {
    color: "#fff",
    fontSize: 20,
  },
  bannerImage: {
    maxWidth: "100%",
    height: "auto",
  },
  bannerButton: {
    fontSize: 24,
    fontWeight: "bold",
    height: 48,
    marginBottom: 24,
  },
  bgWhite: {
    backgroundColor: "#fff",
  },
  bgGradient: {
    backgroundColor: Colors.brandPrimary,
    background: `linear-gradient(30deg, ${Colors.brandPrimary} 0%, ${Colors.brandPrimary} 50%, ${Colors.brandInfo} 100%)`,
  },
  textCenter: {
    textAlign: "center",
  },
  textWhite: {
    color: "#fff",
  },
  valuePropImg: {
    marginBottom: 48,
    width: "100%",
    maxHeight: 144,
  },
  valuePropTitle: {
    marginBottom: 24,
  },
  valuePropButton: {
    fontSize: 20,
    fontWeight: "bold",
    height: 48,
    marginBottom: 50,
    paddingLeft: 40,
    paddingRight: 40,
  },
  pressImg: {
    marginBottom: 24,
    width: "100%",
    maxHeight: 48,
  },
  pressText: {
    fontSize: 14,
    fontStyle: "italic",
    color: "#A492D1",
    marginBottom: 32,
  },
  brandLogo: {
    maxHeight: 120,
  },
  brandName: {
    fontSize: 14,
  },
  requirementImg: {
    marginBottom: 24,
    maxHeight: 16,
  },
  requirementText: {
    fontSize: 14,
    fontStyle: "italic",
    color: "#A492D1",
  },
  section: {
    padding: 50,
    paddingBottom: 88,
    paddingTop: 80,
  },
  sectionTextWrap: {
    paddingTop: 70,
  },
  sectionTitle: {
    fontSize: 32,
    lineHeight: 1,
  },
  sectionText: {
    fontSize: 20,
  },
  sectionImage: {
    maxWidth: "100%",
    height: "auto",
  },
  sectionButton: {
    fontSize: 20,
    fontWeight: "bold",
    height: 48,
    marginBottom: 24,
    paddingLeft: 40,
    paddingRight: 40,
  },
  sectionInput: {
    marginBottom: 24,
  },
  productBrand: {
    fontSize: 10,
    textTransform: "uppercase",
    color: "#977DD1",
    margin: 0,
  },
  productName: {
    fontSize: 24,
    marginBottom: 8,
  },
  productPrice: {
    fontSize: 14,
    color: Colors.brandInfo,
  },
  productDecription: {
    fontSize: 14,
  },
  productShareButton: {
    textTransform: "uppercase",
    height: 48,
    lineHeight: "48px",
  },
}
